<template>
  <div>
    <div class="content">
      <div class="all">
        <div class="SearchBar">
          <el-form
              ref="search"
              :inline="true"
              size="small"
          >
            <el-form-item label="时间段:" class="searchItem">
              <el-date-picker
                  size="mini"
                  clearable
                  style="width: 300px;"
                  v-model="time"
                  type="daterange"
                  range-separator="至"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  @change="timeChange"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="维修员:" class="searchItem">
              <el-input v-model="pageParams.userName" size="mini" placeholder="请输入关键字" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="" class="searchItem">
              <el-button type="primary" @click="searchList" size="mini" >查询</el-button>
              <el-button type="warning" @click="restSearch" size="mini" >重置</el-button>
            </el-form-item>
          </el-form>
          <el-table
              ref="tableData"
              :data="tableData"
              border
              :header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
              height="calc(100vh - 290px)"
              :stripe="true"
          >
            <el-table-column
                type="index"
                label="序号"
                width="50"
            >
            </el-table-column>
            <el-table-column
                prop="userName"
                label="维修员"
            >
            </el-table-column>
            <el-table-column
                prop="wxgdNum"
                label="维修工单数"
            >
            </el-table-column>
            <el-table-column
                prop="bcchfNum"
                label="被抽查回访数"
            >
            </el-table-column>
            <el-table-column
                prop="hfwcNum"
                label="回访完成数"
            >
            </el-table-column>
            <el-table-column
                prop="hfwcl"
                label="回访完成率"
            >
            </el-table-column>
          </el-table>
          <div class="total">
            <el-pagination
                background
                layout="total, prev, pager, next,sizes, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="pageParams.current"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="pageParams.size"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {queryByPagStatistic} from "../../RequestPort/kfOrder";

export default {
  name: "statistics",
  data(){
    return{
      pageParams:{
        current:1,
        size: 100,
        userName:''
      },
      total:null,
      time:[],
      tableData:[]
    }
  },
  mounted() {
    this.loadList(1)
  },
  methods:{
    searchList(){
      this.loadList(1)
    },
    handleCurrentChange(current) {
      this.pageParams.current = current;
      this.loadList();
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.handleCurrentChange(1);
    },
    timeChange(val) {
      if (val) {
        this.pageParams.startDate = val[0];
        this.pageParams.endDate = val[1];
      } else {
        this.pageParams.startDate = null;
        this.pageParams.endDate = null;
      }
    },
    loadList(current){
      if (current) {
        this.pageParams.current = 1
      }
      queryByPagStatistic(this.pageParams).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    restSearch(){
      this.pageParams={
        current:1,
        size: 100,
        userName:''
      }
      this.total= null
      this.time = []
      this.tableData = []
      this.loadList(1)
    },
  }
}
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool{
  height: 25px;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  padding-top: 10px;
  padding-left: 20px;
  background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;height:auto
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 120px);
}
.search{
  width: 190px;
}
.total{
  margin-left: 15px;
  margin-top: 10px;
}
.table{
  overflow:auto;
  height: 520px;
}
</style>